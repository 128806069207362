<template>
  <div>
    <v-dialog
      v-model="isEditSendBodySick"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('editSendBodySick') }}
        </v-card-title>
        <v-form
          ref="formEditSendBodySick"
          @submit.prevent="updateSendBodySick"
        >
          <v-card-text>
            <v-text-field
              v-model="dataEditLocal.phrf_code"
              outlined
              :label="$t('a_number')"
              dense
            ></v-text-field>
            <v-textarea
              v-model="dataEditLocal.phrf_comment"
              outlined
              :label="$t('found_problems')"
              class="mt-3"
              rows="3"
            ></v-textarea>
            <v-checkbox
              v-model="dataEditLocal.phrf_basic_id"
              class="py-0 my-0"
              :true-value="'1'"
              :false-value="'0'"
              hide-details
              :label="$t('providing_initial_treatment_including')"
            ></v-checkbox>
            <v-textarea
              v-model="dataEditLocal.phrf_basic"
              :disabled="dataEditLocal.phrf_basic_id == 0"
              outlined
              rows="3"
            ></v-textarea>
            <v-checkbox
              v-model="dataEditLocal.phrf_suggest_id"
              class="py-0 my-0"
              hide-details
              :true-value="'1'"
              :false-value="'0'"
              :label="$t('give_advice_on_medication')"
            ></v-checkbox>
            <v-textarea
              v-model="dataEditLocal.phrf_suggest"
              :disabled="dataEditLocal.phrf_suggest_id == 0"
              outlined
              rows="3"
            ></v-textarea>
            <v-checkbox
              v-model="dataEditLocal.phrf_note_id"
              class="py-0 my-0"
              :true-value="'1'"
              hide-details
              :false-value="'0'"
              outlined
              :label="$t('other')"
            ></v-checkbox>
            <v-textarea
              v-model="dataEditLocal.phrf_note"
              outlined
              rows="3"
              :disabled="dataEditLocal.phrf_note_id == 0"
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :loading="loading"
              :disabled="loading"
              type="submit"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="$emit('update:is-edit-send-body-sick',false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import sendBodySick from '@/api/drugAndCert/sendBodySick'
import store from '@/store'
import { required } from '@/@core/utils/validation'

export default {
  model: {
    prop: 'isEditSendBodySick',
    event: 'update:is-edit-send-body-sick',
  },
  props: {
    isEditSendBodySick: {
      type: Boolean,
      default: false,
    },
    dataEdit: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const formEditSendBodySick = ref(null)
    const dataEditLocal = ref({})
    const loading = ref(false)

    const { sendBodySickUpdate } = sendBodySick
    const updateSendBodySick = () => {
      const isFormValid = formEditSendBodySick.value.validate()
      if (!isFormValid) return
      loading.value = true
      sendBodySickUpdate(dataEditLocal.value).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update:is-edit-send-body-sick', false)
        loading.value = false
        emit('onUpdate')
      })
    }

    watch(() => props.dataEdit, value => {
      if (value) {
        dataEditLocal.value = JSON.parse(JSON.stringify(value))
      }
    })

    return {
      loading,
      formEditSendBodySick,
      dataEditLocal,
      updateSendBodySick,
      required,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
