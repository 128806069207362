import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const sendBodySickList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'phrf/list',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('Get body sick list err : ', err)

      return {}
    })

  return response
}
const sendBodySickGet = async id => {
  ////await refreshToken()
  const response = await api
    .get({
      path: 'phrf',
      param: id,
    })
    .then(res => (res.data || {}))
    .catch(err => {
      console.log('Get body sick  err : ', err)

      return {}
    })

  return response
}
const sendBodySickUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'phrf',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('Update body sick err : ', err)

      return {}
    })

  return response
}
const sendBodySickStatusUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'phrf/status',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('update status body sick  err : ', err)

      return {}
    })

  return response
}
export default {
  sendBodySickList, sendBodySickUpdate, sendBodySickStatusUpdate, sendBodySickGet,
}
